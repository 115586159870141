import Footer from './common/Footer';
import ComingSoon from './public/ComingSoon';
import './styles/Styles.css';

const App = () => {
  return (
    <div className="min-h-[100dvh] justify-end flex flex-col">
      <ComingSoon />
      <Footer />
    </div>
  );
};

export default App;
