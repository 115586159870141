import logo from '../assets/logo.png';

const CoomingSoon = () => {
  return (
    <div className="w-full flex justify-center items-center flex-1 flex-col md:flex-row py-10 px-4">
      <div className="border-[#953529] border-b-2 md:border-r-2 md:border-b-0 pb-6 md:pr-8 flex flex-col justify-center items-center gap-2">
        <img src={logo} className="h-[120px]" />
        <span className="text-[20px] text-center">
          Prostovoljno gasilsko društvo
          <br />
          Begunje na Gorenjskem
        </span>
      </div>
      <div className="flex flex-col">
        <div className="mt-4 md:ml-8 max-w-[400px] items-center flex flex-col md:items-start">
          <h1 className="text-[22px] uppercase">Obvestila</h1>
          <hr className="w-full mb-2" />
          <h1 className="font-bold mb-1">Spletna stran je v prenovi.</h1>
          <p className="text-[14px] text-center md:text-start">
            Za vse informacije smo dosegljivi na društvenem elektronskem naslovu{' '}
            <a className="underline" href="mailto:info@pgd-begunje.si">
              info@pgd-begunje.si
            </a>
            .
            <br />
            Naše aktivnosti in delo pa lahko spremljate na{' '}
            <a
              className="underline"
              href="https://www.facebook.com/pgdbegunjenagorenjskem"
              target="_blank"
            >
              facebooku
            </a>{' '}
            in{' '}
            <a
              className="underline"
              href="https://www.instagram.com/begunjepgd/"
              target="_blank"
            >
              instagramu
            </a>
            .
          </p>
        </div>
        <div className="mt-4 md:ml-8 max-w-[400px] items-center flex flex-col md:items-start">
          <h1 className="font-bold mb-1">Namenitev dohodnine za donacije</h1>
          <p className="text-[14px] text-center md:text-start">
            <a href="/donacija_dohodnine.pdf" target="_blank">
              Obrazec za namenitev dela dohodnine društvu najdete{' '}
              <span className="underline">tukaj</span>.
            </a>
          </p>
        </div>
        <div className="mt-4 md:ml-8 max-w-[400px] items-center flex flex-col md:items-start">
          <h1 className="font-bold mb-1">Statut 2024</h1>
          <p className="text-[14px] text-center md:text-start">
            <a href="/statut_2024.pdf" target="_blank">
              Osnutek statuta PGD Begunje na Gorenjskem si lahko prebere{' '}
              <span className="underline">tukaj</span>.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CoomingSoon;
